.site-shopping-info {
  overflow: hidden;
  background-color: $andes-white;

  .info-slide {
    box-sizing: border-box;
    padding: rem(35px) rem(45px) rem($andes-spacing-20);
    text-align: center;
    position: relative;

    > .img-container {
      height: rem(72px);
      img {
        vertical-align: middle;
      }
    }

    h2,
    .info-slide__title {
      font-size: rem($font-size-20);
      font-weight: $font-weight-semibold;
      line-height: $line-height-m;
      color: #4b4b4b;
      margin: rem($andes-spacing-16) 0 0;
    }

    p {
      color: $andes-gray-900-solid;
      font-size: rem(15px);
      margin: rem($andes-spacing-4) 0 rem(10px);
      line-height: $line-height-s;
    }

    a {
      color: $andes-blue-400;
      font-size: rem($font-size-14);
      text-decoration: none;
    }
  }

  .info-slide__disclaimer:after {
    content: "*";
  }

  .info-slide__shipping-conditions:before {
    content: "*";
    padding-left: rem($andes-spacing-4);
    padding-right: rem($andes-spacing-4);
  }
}

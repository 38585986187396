@mixin info-slide-card {
  .info-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: start;

    .img-container {
      display: flex;
      margin: 0px;
      order: 3;
      padding: 0px;
    }

    h1, h2, .info-slide__title {
      margin: rem($andes-spacing-16) 0;
    }
  }

  .info-slide:not(:first-child):before {
    display: none;
  }
}

@import '../../commons/core';
@import '../../commons/hidden';
@import '../../commons/variables';

.section-category-item__container {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: minmax(100%, 1fr);
  grid-template-rows: repeat(2, minmax(0px, max-content));
  height: 100%;
  width: 100%;
}

.section-category-item__image-container {
  align-items: center;
  display: flex;
  grid-column: 1/2;
  grid-row: 1/2;
  justify-content: center;
}

.section-category-item__image {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.section-category-item__header {
  align-items: center;
  display: grid;
  grid-column: 1/2;
  grid-row: 2/3;
  grid-template-columns: minmax(0px, 100%);
  grid-template-rows: repeat(2, minmax(0px, auto));
  overflow: hidden;
  row-gap: rem($andes-spacing-4);
}


.section-category-item__title {
  color: $andes-black;
  font-weight: $font-weight-bold;
  grid-column: 1/2;
  grid-row: 1/2;
  line-height: $line-height-s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section-category-item__price-container {
  color: $color-price-explorer;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: hidden;
  text-align: right;
}

.section-category-item__price {
  display: block;
  font-weight: $font-weight-regular;
  line-height: $line-height-s;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section-category-item__button-container {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
}

.section-category-item__button {
  background-color: transparent;
  border: none;
  color: $color-icon-button-explorer;
  cursor: pointer;
  display: block;
  flex: 1 1 $max-size-button-explorer;
  font-size: rem($font-size-16);
  font-weight: $font-weight-regular;
  line-height: $line-height-s;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section-category-item__subtitle {
  font-weight: $font-weight-regular;
  line-height: $line-height-s;
  margin-right: rem($andes-spacing-4);
}

.section-category-item--hide {
  .section-category-item__image-container {
    grid-row: 1/2;
  }

  .section-category-item__container {
    grid-template-rows: 100%;
  }
}

.section-category-item__button-container svg path {
  fill: $color-icon-button-explorer;
}


.section-category-item__title--hidden.section-category-item__price--hidden.section-category-item__button--hidden {
  display: none;
}

.section-category__fake-item .section-category-item__image-container,
.section-category-item__image-default {
  border: rem(1px) solid $color-border-image-explorer;
}

.section-category__fake-item .section-category-item__image-container {
  align-items: center;
  display: flex;
  justify-content: center;

  .section-category-item__image {
    height: 30%;
    width: auto;
  }
}

.section-category-item__price-cta-container {
  display: flex;
  grid-column: 1/2;
  grid-row: 2/3;
}

.section-category-item {
  align-self: start;
  height: auto;
}

$home-z-index: (
  over-header-editable: 2050,
  header-editable: 2000,
  cookie-disclaimer: 1020,
  eshop-header: 909,
  below-eshop-header: 908,
  delete-section-button: 500,
  over-header: 110,
  header: 100,
  below-header: 50,
  payment-over-banner: 40,
  over-footer: 10,
  editable: 10,
  wrapper-editable: 2,
  bottom: 0,
);

@mixin z-index($key) {
  z-index: map-get($home-z-index, $key);
}

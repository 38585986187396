@import 'core';

.section-category-item__header {
  padding-top: rem($andes-spacing-8);
}

.section-category-item__title {
  font-size: rem($font-size-16);
}

.section-category-item__price {
  font-size: rem($font-size-16);
  padding-right: rem($andes-spacing-12);
}

.section-category-item__subtitle {
  font-size: rem($font-size-16);
}

.section-category-item__button {
  margin-right: rem($andes-spacing-4);
  padding: 0;
}

.section-category-item__title--hidden {
  grid-template-rows: minmax(auto, 1fr);

  .section-category-item__price-cta-container {
    grid-row: 1/2;
  }
}

.section-category-item__price--hidden .section-category-item__button {
  margin-left: 0;
  padding-left: 0;
}

.section-category-item__price-cta-container {
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
